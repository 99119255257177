import {
  Typography,
  Box,
  InputAdornment,
  Button as MuiButton,
} from '@mui/material';
import { AlertColor } from '@mui/lab';
import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import DocumentTemplatesClient from 'shared/clients/DocumentTemplatesClient';
import SearchIcon from '@mui/icons-material/Search';
import Table from '../Table';
import Button from '../Button';
import CustomTextField from '../TextField/CustomTextField';
import AlertWithCustomStyles from '../Alert';
import { ActionType } from 'types/action';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import './index.scss';

const columns = [
  { key: 'name', header: 'Nombre documento' },
  { key: 'insurance', header: 'Aseguradora' },
  { key: 'id', header: 'ID documento' },
  { key: 'date', header: 'Fecha de Creación' },
  { key: 'agent', header: 'Agente' },
];

const ListDocument: FunctionComponent<any> = ({ setLoader }): JSX.Element => {
  const effectRan = useRef(false);
  const [documents, setDocuments] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [showSnackbarPhoto, setShowSnackbarPhoto] = useState<boolean>(false);
  const { dispatch: inspectDispatch, state: inspectContext } = useContext<
    IInspectionContext
  >(InspectionContext);
  const [typeMessagePhoto, setTypeMessagePhoto] = useState<
    AlertColor | undefined
  >('success');
  const [alertMessagePhoto, setAlertMessagePhoto] = useState<string>('');
  const history = useHistory();

  const handleClickPrimary = () => {
    history.push('/CreateTemplate');
  };

  useEffect(() => {
    if (effectRan.current === false) {
      const getDataInitial = async () => {
        setLoader(true);
        const docs = await new DocumentTemplatesClient().getAllTemplates();
        setDocuments(docs);
        setLoader(false);
      };

      getDataInitial();
      effectRan.current = true;
    }
  }, []);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredDocuments = documents.filter((doc: any) =>
    doc.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleEdit = (rowData) => {
    history.push('/editTemplate/' + rowData.id);
  };

  const handleAdd = (rowData) => {
    history.push('/setVariablesTemplate/' + rowData.id);
  };

  const handleDelete = (rowData) => {
    console.warn('Delete:', rowData);
  };

  const handleSnackBarClearMessagePhoto = (): void => {
    setShowSnackbarPhoto(false);
    inspectDispatch({
      type: ActionType.SET_PHOTO_ALERT,
      payload: {
        showAlertPhoto: false,
        alertMessagePhoto: '',
        alertTypePhoto: '',
      },
    });
  };

  useEffect(() => {
    if (
      inspectContext.showAlertPhoto &&
      inspectContext.alertMessagePhoto &&
      inspectContext.alertTypePhoto &&
      String(inspectContext.alertMessagePhoto).length > 0
    ) {
      setShowSnackbarPhoto(true);
      setTypeMessagePhoto(inspectContext.alertTypePhoto as AlertColor);
      setAlertMessagePhoto(inspectContext.alertMessagePhoto);
    }
  }, [
    inspectContext.showAlertPhoto,
    inspectContext.alertMessagePhoto,
    inspectContext.alertTypePhoto,
  ]);

  return (
    <>
      <AlertWithCustomStyles
        showSnackbarPhoto={showSnackbarPhoto}
        handleSnackBarClearMessagePhoto={handleSnackBarClearMessagePhoto}
        typeMessagePhoto={typeMessagePhoto}
        alertMessagePhoto={alertMessagePhoto}
        bgColor="rgba(67, 160, 71, 1)"
      />
      <Typography className="documents__title">Documentos</Typography>
      <Box className="documents__content">
        <CustomTextField
          id="filled-basic"
          label="Filtrar nombre"
          variant="filled"
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="primary"
          size="md"
          radius="full"
          onClick={handleClickPrimary}
        >
          Crear Documento
        </Button>
      </Box>
      <Table
        columns={columns}
        data={filteredDocuments}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleAdd={handleAdd}
      />
    </>
  );
};

export default ListDocument;
