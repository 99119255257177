import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
  & .MuiFormLabel-asterisk {
    color: #f15b2b;
  }
  & .MuiInputLabel-asterisk {
    color: #f15b2b;
  }
  & .MuiFilledInput-root {
    min-width: 296px;
    border: 3px solid;
    border-radius: 0px;
    border-color: #a3bed6;
    transition: background-color 0.3s ease, border-color 0.3s ease;
    background: white;
    caret-color: #2979ff;
    &:hover {
      background-color: #f9fcff;
      border-color: #a3bed6;
    }
    &:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
    &::before {
      border-bottom: none;
    }
    &::after {
      border-bottom: none;
      color: black;
    }
    &.Mui-focused {
      background-color: white;
      border-color: #2979ff;
    }
  }
  & .MuiInputLabel-root {
    color: #001d3d;
    top: 4px;
  }
  & .MuiInputAdornment-root {
    color: #001d3d;
  }
  & .MuiInputLabel-shrink {
    color: black;
  }
`;

const CustomTextField: React.FC<TextFieldProps> = (props) => {
  return <StyledTextField {...props} />;
};

export default CustomTextField;
