import { Typography, Box } from '@mui/material';
import ButtonClaims from './../../claims/button';
import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import Loader from 'components/common/loader/Loader';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { ActionType } from 'types/action';
import { useHistory } from 'react-router-dom';
import DocumentTemplatesClient from 'shared/clients/DocumentTemplatesClient';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomTextField from '../TextField/CustomTextField';
import Button from '../Button';
import './index.scss';

interface ChipData {
  label: string;
}

const CreateDocument: FunctionComponent<any> = ({ setLoader }): JSX.Element => {
  const effectRan = useRef(false);
  const [documents, setDocuments] = useState<any>([]);
  const [emails, setEmails] = useState<ChipData[]>([]);
  const [email, setEmail] = useState('');
  const [copyDoc, setCopyDoc] = useState('');
  const [name, setName] = useState(undefined);
  const [insurance, setInsurance] = useState('');
  const [idName, setIdName] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [disabledMail, setDisabledMail] = useState(true);
  const [loading, setLoading] = useState(false);
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const history = useHistory();

  useEffect(() => {
    if (effectRan.current === false) {
      const getDataInitial = async () => {
        setLoader(true);
        const docs = await new DocumentTemplatesClient().getAllTemplates();
        setDocuments(docs);
        setLoader(false);
      };

      getDataInitial();
      effectRan.current = true;
    }
  }, []);

  useEffect(() => {
    setDisabled(!(emails.length > 0 && name));
  }, [emails, name]);

  useEffect(() => {
    setDisabledMail(
      !(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email) ||
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.[A-Za-z]+\.[A-Za-z]+$/.test(email)
      ),
    );
  }, [email]);

  const handleAddEmail = () => {
    const data = email;
    const emailsList = emails;
    if (emailsList.indexOf({ label: data }) === -1) {
      setEmails((prevEmails) => [...prevEmails, ...[{ label: data }]]);
      setEmail('');
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setEmails((chips) => chips.filter((chip) => chip.label !== chipToDelete));
  };

  const changeEmail = (event) => {
    setEmail(event?.target.value);
  };
  const changeDoc = (event) => {
    setCopyDoc(event?.target.value);
  };
  const onReturn = () => {
    history.push('/documentTemplates');
  };
  const onChangeName = (event: any) => {
    setName(event?.target.value);
  };

  const onCreateTemplate = async () => {
    const mails = emails.map(({ label }) => label);
    const data = {
      id: null,
      name: name,
      documentTypeId: 0,
      permissionEmails: mails,
      copyId: copyDoc ? (copyDoc === '0' ? null : copyDoc) : null,
    };
    setLoading(true);
    await new DocumentTemplatesClient().createTemplate(data);
    dispatch({
      type: ActionType.SET_PHOTO_ALERT,
      payload: {
        showAlertPhoto: true,
        alertMessagePhoto: 'Documento Agregado',
        alertTypePhoto: 'success',
      },
    });
    history.push('/documentTemplates');
  };

  return (
    <>
      <Loader isActive={loading} />
      <Box
        className="create create__subtitle"
        display="flex"
        alignItems="center"
        onClick={onReturn}
        sx={{ cursor: 'pointer' }}
        style={{ cursor: 'pointer' }}
      >
        <ArrowBackIcon />
        <span style={{ marginLeft: '8px' }}>
          cancelar y volver a documentos
        </span>
      </Box>
      <Box className="create__container">
        <Typography className={'create create__title'}>
          Crear Documento
        </Typography>
      </Box>
      <Box className="create__section">
        <Box className="create__card">
          <Box className="create create__card--title">
            información general del documento
          </Box>
          <Box className="create create__card--subtitle">
            Ingrese la información para definir el documento.
          </Box>
          <Box
            className="create__card--fields"
            component="form"
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
              },
              gap: 2,
              '& .MuiTextField-root': { m: 1, width: '100%' },
            }}
            autoComplete="off"
          >
            <CustomTextField
              id="filled-basic"
              label="Nombre del documento"
              variant="filled"
              value={name}
              required
              onChange={onChangeName}
            />

            <CustomTextField
              id="filled-basic"
              label="Aseguradora"
              variant="filled"
              value={insurance}
              required
            />

            <CustomTextField
              id="filled-basic"
              label="ID del documento asociado"
              variant="filled"
              value={idName}
              required
            />
          </Box>
        </Box>
        <Box className="create__card">
          <Box className="create create__card--title">
            agregar contenido al documento
          </Box>
          <Box className="create create__card--subtitle">
            Complete la información y repita hasta incluir todo el contenido del
            documento.
          </Box>
          <Box
            className="create__card--fields"
            component="form"
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
              },
              gap: 2,
              '& .MuiTextField-root': { m: 1 },
            }}
          >
            <CustomTextField
              id="filled-select-document-native"
              select
              label="Crear basado en un documento"
              value={copyDoc}
              onChange={changeDoc}
              SelectProps={{
                native: true,
              }}
              variant="filled"
              sx={{ minWidth: 280 }}
            >
              <option value=""></option>
              {documents.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
            </CustomTextField>
            <CustomTextField
              label="Nuevo correo"
              variant="filled"
              value={email}
              required
              onChange={changeEmail}
            />
            <Box className="box">
              <Button
                variant="secondary"
                size="lg"
                radius="none"
                onClick={handleAddEmail}
                disabled={disabledMail}
              >
                Agregar
              </Button>
            </Box>
          </Box>
        </Box>
        {emails.length > 0 && (
          <Box className="create__contentList">
            {emails ? (
              <>
                <p className="create__contentList--title">
                  Correos Electrónicos con Acceso
                </p>
                {emails.map((data, index) => {
                  return (
                    <div key={index} className="create__contentList--section">
                      <label>{data.label}</label>
                      <Button
                        variant="tertiary"
                        size="md"
                        onClick={handleDelete(data.label)}
                      >
                        Eliminar
                      </Button>
                    </div>
                  );
                })}
              </>
            ) : null}
          </Box>
        )}
        <Box className="create__content">
          <span>* Campo obligatorio</span>
          <Button
            variant="primary"
            size="md"
            radius="full"
            onClick={onCreateTemplate}
            disabled={!name || emails.length === 0}
          >
            Guardar
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CreateDocument;
