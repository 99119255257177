import { Typography, MenuItem, Grid, Box } from '@mui/material';
import ConnectServicesClient from 'shared/clients/ConnectServiceClient';
import DocumentTemplatesClient from 'shared/clients/DocumentTemplatesClient';
import ButtonClaims from './../../claims/button';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import Loader from 'components/common/loader/Loader';
import DataTable from 'components/claims/grid';
import CustomTextField from '../TextField/CustomTextField';
import Button from '../Button';
import Modal from '../Modal';
import './index.scss';

interface Props {
  idTemplate: string;
}
export interface Document {
  id: string;
  name: string;
  permissionEmails: string;
  documentId: string;
  variables: string[];
}
export interface Variable {
  id: string;
  title: string;
  finally: boolean;
  children: Variable[];
}
export interface VariableTemplate {
  TemplateId: string;
  Variable: string;
  FormTypeId: string;
  FieldId: string;
  FieldName: string;
  FieldRoot: string;
}
export interface Variable {
  id: string;
  title: string;
  finally: boolean;
  children: Variable[];
}
const SetVariablesDocument: FunctionComponent<Props> = ({
  idTemplate,
}): JSX.Element => {
  const [document, setDocument] = useState<any>(null);
  const [variablesTemplate, setVariablesTemplate] = useState<any>([]);
  const [variablesTemplateHistory, setVariablesTemplateHistory] = useState<any>(
    [],
  );
  const [variables, setVariables] = useState<any>([]);
  const [variableSelected, setVariableSelected] = useState(null);
  const [formType, setFormType] = useState<any>(null);
  const [selectedValue, setSelectedValue] = React.useState(null);
  const [selectedVarName, setSelectedVarName] = React.useState(null);
  const [variableTreeView, setVariableTreeView] = useState<Variable[]>([]);
  const [disabled, setDisabled] = useState(true);
  const [dataHistoric, setDataHistoric] = useState<any>([]);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState<string | undefined>('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getVariables();
    getTemplate();
  }, []);
  const getFields = async () => {
    if (formType) {
      setLoading(true);
      const data = await new ConnectServicesClient().getFieldsTemplates(
        formType,
        Number(localStorage.getItem('companyId')).toString(),
      );
      setVariables((prevtre) => [...data.data]);
      setLoading(false);
    }
  };
  const getDataHistoric = async () => {
    if (formType) {
      const data = await new ConnectServicesClient().getVariablesTemplate(
        formType,
        idTemplate,
      );
      setDataHistoric((prevtre) => [...data.data]);
      setLoading(false);
    }
  };

  const getTemplate = async () => {
    const data = await new DocumentTemplatesClient().getAllTemplates();
    setDocument((prevtre) => data.find((x) => x.id === idTemplate));
  };
  const getVariables = async () => {
    const data = await new DocumentTemplatesClient().getVariables(idTemplate);
    const dataFinal = data.filter((x) => !x.startsWith('currentDate'));
    setVariablesTemplateHistory((prevtm) => [...dataFinal]);
    setVariablesTemplate((prevtm) => [...dataFinal]);
  };
  useEffect(() => {
    getDataHistoric();
    getFields();
  }, [formType]);

  useEffect(() => {
    const data = variablesTemplateHistory.filter(function (item) {
      return dataHistoric.map((exam) => exam.variable).indexOf(item) === -1;
    });
    setVariablesTemplate((old) => [...data]);
  }, [dataHistoric]);
  useEffect(() => {
    setDisabled(!(formType && variableSelected && selectedValue));
  }, [formType, variableSelected, selectedValue]);
  useEffect(() => {
    const treeView: Variable[] = [];
    for (let i = 0; i < variables.length; i++) {
      let item = treeView.find((x) => x.title === variables[i].stageTitle);
      if (!item) {
        item = {
          id: '0',
          finally: false,
          title: variables[i].stageTitle,
          children: [],
        };
        item.children = setChildren(item.title, 2, variables, item.children);
        treeView.push(item);
      }
    }
    setVariableTreeView((prevtre) => [...treeView]);
  }, [variables]);

  const handleChange = (event) => {
    const valu = event?.target.value;
    setSelectedValue(valu);
    setSelectedVarName(
      variables.find((x) => x.fieldKeyName === valu).fieldLabel,
    );
  };

  const handleChangeFields = (event) => {
    const valu = event?.target.value;
    setSelectedValue(valu);
    setSelectedVarName(
      variables.find((x) => x.fieldKeyName === valu).fieldLabel,
    );
  };

  const onChangeFormType = (event) => {
    setFormType(event?.target.value);
  };
  const onChangeSelectedVariable = (event) => {
    setVariableSelected(event?.target.value);
  };
  const setChildren = (parent, level, data, items) => {
    for (let i = 0; i < data.length; i++) {
      if (parent === (level === 2 ? data[i].stageTitle : data[i].title)) {
        let item = items.find(
          (x) => x.title === (level === 2 ? data[i].title : data[i].fieldLabel),
        );
        if (!item) {
          item = {
            title: level === 2 ? data[i].title : data[i].fieldLabel,
            id:
              data[i].level === level
                ? data[i].fieldKeyName.toString()
                : data[i].fieldKey.toString(),
            finally: data[i].level === level,
            children: [],
          };
          if (level < 3) {
            item.children = setChildren(
              item.title,
              level + 1,
              data,
              item.children,
            );
          }
          items.push(item);
        }
      }
    }

    return items;
  };

  const handleAddVariable = async () => {
    const data = {
      FieldId: selectedValue,
      FormTypeId: parseInt(formType),
      TemplateId: idTemplate,
      Variable: variableSelected,
      FieldName: selectedVarName,
      FieldRoot: selectedVarName,
    };
    const result = await new ConnectServicesClient().addVariableTemplate(data);
    if (result.data) {
      getDataHistoric();
      resetForm();
    }
  };
  const resetForm = () => {
    setSelectedValue(null);
    setSelectedVarName(null);
    setVariableSelected(null);
  };
  const columns: GridColDef[] = [
    { field: 'variable', headerName: 'Variable', width: 200 },
    { field: 'fieldName', headerName: 'Nombre de campo', width: 400 },
  ];

  const openConfirmDialog = (id: string | undefined): void => {
    setDeleteId(id);
    setOpenDialogConfirm(true);
  };
  const handleConfirm = async (): Promise<void> => {
    setOpenDialogConfirm(false);
    setLoading(true);
    await handleDelete();
  };
  const handleDelete = async (): Promise<void> => {
    if (deleteId) {
      await new ConnectServicesClient().deleteVariableTemplate(deleteId);
    }
    setDeleteId(undefined);
    getDataHistoric();
  };

  const flattenTree = (tree: Variable[]): Variable[] => {
    let result: Variable[] = [];
    tree.forEach((node: Variable) => {
      if (node.finally) {
        result.push(node);
      }
      if (node.children) {
        result = result.concat(flattenTree(node.children));
      }
    });

    return result;
  };

  const flatOptions = flattenTree(variableTreeView);

  return (
    <>
      <Loader isActive={loading} />
      <Box className="create__container">
        <Typography className={'create create__title'}>
          configurar Documento - {document?.name}{' '}
        </Typography>
      </Box>
      <Box className="variable__card">
        <Box className="variable variable__card--title">
          agregar contenido al documento
        </Box>
        <Box className="variable variable__card--subtitle">
          Complete la información y repita hasta incluir todo el contenido del
          documento.
        </Box>
        <Box
          className="variable__card--fields"
          component="form"
          sx={{
            display: 'flex',
            flexDirection: {
              xs: 'column',
              sm: 'column',
              md: 'column',
              lg: 'row',
            },
            gap: 2,
            '& .MuiTextField-root': { m: 1 },
          }}
        >
          <CustomTextField
            id="filled-select-document-native"
            select
            label="Tipo de formulario"
            value={formType}
            onChange={onChangeFormType}
            SelectProps={{
              native: false,
            }}
            variant="filled"
            required
            sx={{ minWidth: 280, backgroundColor: '#ffffff' }}
          >
            <MenuItem value="6">Claim Management</MenuItem>
            <MenuItem value="1">Inspección</MenuItem>
          </CustomTextField>
          <CustomTextField
            id="filled-select-document-native"
            select
            label="Variables"
            value={variableSelected}
            onChange={onChangeSelectedVariable}
            SelectProps={{
              native: false,
            }}
            variant="filled"
            required
            sx={{ minWidth: 280, backgroundColor: '#ffffff' }}
          >
            {variablesTemplate &&
              variablesTemplate.map((vari, index) => {
                return (
                  <MenuItem key={index} value={vari}>
                    {vari}
                  </MenuItem>
                );
              })}
          </CustomTextField>
          <CustomTextField
            id="filled-select-document-native"
            select
            label="Campo"
            value={selectedValue}
            onChange={handleChangeFields}
            SelectProps={{
              native: false,
            }}
            variant="filled"
            required
            sx={{ minWidth: 280, backgroundColor: '#ffffff' }}
          >
            {flatOptions.map((option: any, index) => (
              <MenuItem key={index} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </CustomTextField>
          <Box className="box">
            <Button
              variant="secondary"
              size="lg"
              radius="none"
              onClick={handleAddVariable}
              disabled={disabled}
            >
              Agregar
            </Button>
          </Box>
        </Box>
      </Box>

      {dataHistoric.length > 0 && (
        <Box className="custom-table">
          <Box className="table-header">
            <Box className="header-cell">Variable</Box>
            <Box className="header-cell">Campo</Box>
            <Box className="header-cell"></Box>
          </Box>
          {dataHistoric.map((data, index) => (
            <Box key={index} className="table-row">
              <Box className="cell">{data.variable}</Box>
              <Box className="cell">{data.fieldName}</Box>
              <Box className="cell cell-button">
                <Button
                  variant="tertiary"
                  size="md"
                  onClick={() => openConfirmDialog(data.id)}
                >
                  Eliminar
                </Button>
              </Box>
            </Box>
          ))}
        </Box>
      )}
      <Modal
        title="¿seguro que quiere eliminar este registro?"
        messageStyle={{ fontSize: '16px' }}
        message="Si no guarda los cambios, perderá todo el contenido ingresado y editado."
        open={openDialogConfirm}
        handleClose={() => setOpenDialogConfirm(false)}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

export default SetVariablesDocument;
