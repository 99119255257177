import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  // Button,
  Grid,
} from '@mui/material';
import Button from '../Button';
import './index.scss';

interface MyDialogProps {
  title?: string;
  messageStyle?: React.CSSProperties;
  message: string;
  open: boolean;
  handleClose: () => void;
  handleConfirm?: () => void;
}

const Modal: FC<MyDialogProps> = ({
  title,
  messageStyle,
  message,
  open,
  handleClose,
  handleConfirm,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '0px',
        },
      }}
    >
      <DialogTitle className="dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className="dialog-subtitle" style={messageStyle}>
          {message}
        </DialogContentText>
      </DialogContent>
      <Grid style={{ display: 'flex', justifyContent: 'center' }}>
        {handleConfirm && (
          <DialogActions>
            <Button
              variant="secondary"
              size="md"
              radius="none"
              onClick={handleClose}
            >
              Cancelar
            </Button>
          </DialogActions>
        )}
        <DialogActions>
          <Button
            variant="primary"
            size="md"
            radius="none"
            onClick={handleConfirm}
          >
            Si, Guardar
          </Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default Modal;
