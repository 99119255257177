import { Typography, styled, Box } from '@mui/material';
import ButtonClaims from './../../claims/button';
import React, {
  FunctionComponent,
  useEffect,
  useState,
  useRef,
  useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { ActionType } from 'types/action';
import DocumentTemplatesClient from 'shared/clients/DocumentTemplatesClient';
import CustomTextField from '../TextField/CustomTextField';
import Button from '../Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from 'components/common/loader/Loader';
import './index.scss';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
interface ChipData {
  label: string;
}
interface Document {
  id: string;
  name: string;
  permissionEmails: string;
  documentId: string;
}

const EditDocument: FunctionComponent<any> = ({
  idTemplate,
  setLoader,
}): JSX.Element => {
  const effectRan = useRef(false);
  const [emails, setEmails] = useState<ChipData[]>([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [ulrDoc, setUrlDoc] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [disabledMail, setDisabledMail] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );

  useEffect(() => {
    if (effectRan.current === false) {
      const getDataInitial = async () => {
        setLoader(true);
        const client = new DocumentTemplatesClient();
        await client.enabledPermission({ id: idTemplate });
        const docs = await client.getAllTemplates();
        const doc = docs.find((x) => x.id === idTemplate);
        setName(doc.name);
        const ulrd = `https://docs.google.com/document/d/${doc.documentId}/edit`;
        setUrlDoc(ulrd);
        const mails = doc.permissionEmails;
        setEmails((prevEmails) => [
          ...prevEmails,
          ...mails.map((str) => ({ label: str })),
        ]);
        setLoader(false);
      };

      getDataInitial();
      effectRan.current = true;
    }
  }, []);

  useEffect(() => {
    setDisabled(!(emails.length > 0 && name));
  }, [emails, name]);
  useEffect(() => {
    setDisabledMail(
      !(
        /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email) ||
        /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+\.[A-Za-z]+$/.test(email)
      ),
    );
  }, [email]);
  const handleAddEmail = () => {
    const data = email;
    const emailsList = emails;
    if (emailsList.indexOf({ label: data }) === -1) {
      setEmails((prevEmails) => [...prevEmails, ...[{ label: data }]]);
      setEmail('');
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setEmails((chips) => chips.filter((chip) => chip.label !== chipToDelete));
  };
  const changeEmail = (event) => {
    setEmail(event?.target.value);
  };
  const onReturn = () => {
    history.push('/documentTemplates');
  };
  const onUpdateTemplate = async () => {
    const mails = emails.map(({ label }) => label);
    const data = {
      id: idTemplate,
      name: name,
      documentTypeId: 0,
      permissionEmails: mails,
      copyId: null,
    };

    setLoading(true);
    const client = new DocumentTemplatesClient();
    await client.updateTemplate(data);
    await client.removePermissions(idTemplate);

    dispatch({
      type: ActionType.SET_PHOTO_ALERT,
      payload: {
        showAlertPhoto: true,
        alertMessagePhoto: 'Guardado con Éxito',
        alertTypePhoto: 'success',
      },
    });
    history.push('/documentTemplates');
  };

  return (
    <>
      <Loader isActive={loading} />
      <Box
        className="update update__subtitle"
        display="flex"
        alignItems="center"
        onClick={onReturn}
        sx={{ cursor: 'pointer' }}
        style={{ cursor: 'pointer' }}
      >
        <ArrowBackIcon />
        <span style={{ marginLeft: '8px' }}>
          cancelar y volver a documentos
        </span>
      </Box>
      <Box className="update__container">
        <Typography className={'update update__title'}>
          editar documento
        </Typography>
      </Box>
      <Box className="update__section">
        <Box className="update__card">
          <Box className="update update__card--title">
            agregar contenido al documento
          </Box>
          <Box className="update update__card--subtitle">
            Complete la información y repita hasta incluir todo el contenido del
            documento.
          </Box>
          <Box
            className="update__card--fields"
            component="form"
            sx={{
              display: 'flex',
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
              },
              gap: 3,
            }}
          >
            <CustomTextField
              id="filled-basic"
              label="Nombre del documento"
              variant="filled"
              value={name}
              disabled
              sx={{ minWidth: 280 }}
            />

            <CustomTextField
              label="Nuevo correo"
              variant="filled"
              value={email}
              required
              onChange={changeEmail}
            />

            <Box className="box">
              <Button
                variant="secondary"
                size="lg"
                radius="none"
                onClick={handleAddEmail}
                disabled={disabledMail}
              >
                Agregar
              </Button>
            </Box>
          </Box>
        </Box>
        {emails.length > 0 && (
          <Box className="update__contentList">
            {emails ? (
              <>
                <p className="update__contentList--title">
                  Correos Electrónicos con Acceso
                </p>
                {emails.map((data, index) => {
                  return (
                    <div key={index} className="update__contentList--section">
                      <label>{data.label}</label>
                      <Button
                        variant="tertiary"
                        size="md"
                        onClick={handleDelete(data.label)}
                      >
                        Eliminar
                      </Button>
                    </div>
                  );
                })}
              </>
            ) : null}
          </Box>
        )}
        <Box sx={{ height: '500px', position: 'relative' }}>
          <iframe src={ulrDoc} style={{ width: '100%', height: '100%' }} />
        </Box>
        <Box className="update__content">
          <span>* Campo obligatorio</span>
          <Button
            variant="primary"
            size="md"
            radius="full"
            onClick={onUpdateTemplate}
            disabled={!name || emails.length === 0}
          >
            Guardar
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default EditDocument;
