import React from 'react';
import Button from '../Button';
import './index.scss';

const Table = ({ columns, data, handleEdit, handleDelete, handleAdd }) => {
  // Añade una columna para los botones
  const columnsWithActions = [...columns, { key: 'actions', header: '' }];

  return (
    <table className="custom-table">
      <thead>
        <tr>
          {columnsWithActions.map((column) => (
            <th key={column.key}>{column.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((rowData, index) => (
          <tr key={rowData.id} className={index % 2 === 0 ? 'even' : 'odd'}>
            {columnsWithActions.map((column) => (
              <td key={`${rowData.id}-${column.key}`}>
                {column.key === 'actions' ? (
                  <div className="button-group">
                    <Button
                      variant="secondary"
                      size="sm"
                      radius="full"
                      onClick={() => handleEdit(rowData)}
                    >
                      Editar
                    </Button>
                    <Button
                      style={{ marginLeft: '0.5rem' }}
                      variant="secondary"
                      size="sm"
                      radius="full"
                      onClick={() => handleAdd(rowData)}
                    >
                      Añadir
                    </Button>
                    <Button
                      variant="tertiary"
                      size="sm"
                      onClick={() => handleDelete(rowData)}
                    >
                      Eliminar
                    </Button>
                  </div>
                ) : column.render ? (
                  column.render(rowData)
                ) : (
                  rowData[column.key]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
