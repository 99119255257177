import React from 'react';
import {
  ShowChart,
  SettingsOutlined,
  ExitToAppOutlined,
  Assignment,
  AddCircleOutlineOutlined,
  MiscellaneousServices,
  Description,
  PostAdd,
} from '@mui/icons-material';

import { ReactComponent as FieldDownloadOutline } from 'assets/icons/file-download-outline.svg';
import PATH_ROUTES from 'static/constants/path-routes';
import { Tab } from 'types/tab';
import { CmtCountries, CountryCode } from 'types/common';

const TABS: Tab[] = [
  {
    value: 'inspection',
    label: 'Inspección',
    icon: <Assignment />,
    isActive: true,
    subtabs: [
      {
        value: 'activity',
        label: 'Actividad',
        icon: <ShowChart />,
        route: CmtCountries.includes(
          localStorage.getItem('countryCode') as CountryCode,
        )
          ? PATH_ROUTES.EVENTS_CLAIMS
          : PATH_ROUTES.EVENTS,
        isActive: true,
      },
      {
        value: 'report',
        label: 'Reporte',
        icon: <FieldDownloadOutline />,
        route: PATH_ROUTES.REPORTS,
        isActive: true,
      },
      {
        value: 'approval',
        label: 'Aprobaciones',
        icon: <Assignment />,
        route: PATH_ROUTES.APPROVAL,
        isActive: true,
      },
      {
        value: 'documentTemplates',
        label: 'Documentos',
        icon: <Description />,
        route: PATH_ROUTES.DocumentTemplates,
        isActive: true,
      },
    ],
  },
  {
    value: 'preinspection',
    label: 'PreInspección',
    icon: <Assignment />,
    isActive: true,
    subtabs: [
      {
        value: 'activity',
        label: 'Actividad',
        icon: <ShowChart />,
        route: PATH_ROUTES.PRE_INSPECTIONS,
        isActive: true,
      },
      {
        value: 'createPreinspection',
        label: 'Crear Preinspección',
        icon: <PostAdd />,
        route: PATH_ROUTES.PRE_INSPECTION_CREATE,
        isActive: true,
      },
      {
        value: 'configDamages',
        label: 'Parámetros Daños',
        icon: <MiscellaneousServices />,
        route: PATH_ROUTES.PRE_INSPECTION_CONFIG_DAMAGES,
        isActive: true,
      },
      {
        value: 'report',
        label: 'Reporte',
        icon: <FieldDownloadOutline />,
        route: PATH_ROUTES.REPORTS,
        isActive: true,
      },
    ],
  },
];

const OPTIONS_TABS: Tab[] = [
  {
    value: 'settings',
    label: 'Ajustes',
    icon: <SettingsOutlined />,
    route: PATH_ROUTES.SETTINGS,
    isActive: true,
  },
  {
    value: 'logout',
    label: 'Cerrar Sesión',
    icon: <ExitToAppOutlined />,
    isActive: true,
  },
];

export { TABS, OPTIONS_TABS };
